.custom-badge {
  width: max-content;
  position: absolute;
  right: -15px;
  top: 15px;
  display: flex;
  height: 30px;
  padding: 0px 20px 0px 35px;
  align-items: center;

  & p {
    margin: 0 !important;
    color: #fff;
  }
}

.svg {
  position: absolute;
  right: 0px;
  top: 15px;
  float: right;
}

.tail {
  transform: translate(211px, -31px);
}

.custom-badge:after {
  content: '';
  position: absolute;
  left: 0px;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 15px solid #fff;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
}
