.hr-dashboard {
  .hrDashboardInfoSection {
    min-height: 35vh;
  }

  .user-card {
    max-width: 200px;
    max-height: 260px;
    padding-bottom: 0px;
    text-align: center;
    box-shadow: 2px 2px 5px 1px lighten($color-additional, 30%),
      -2px -2px 5px 1px lighten($color-additional, 30%);

    img {
      width: 100px;
      height: 100px;
      object-fit: cover;
      border-radius: 50%;
      margin-bottom: 1rem;
    }

    h5 {
      font-weight: bold;
      letter-spacing: 0.05rem;
    }

    p {
      color: $color-additional;
      margin-top: 0.2rem;
    }

    @media screen and (max-width: $mobile-breakpoint) {
      max-width: 100%;

      img {
        max-width: 150px;
      }
    }
  }

  // Left Section
  .dashboard-left-section {
    .hr-dashboard-calender {
      .calendar {
        height: 300px !important;
      }
    }
  }
  // Right section
  .dashboard-right-section {
    .upcoming-meetings {
      .hr-dashboard__meeting-card {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        box-shadow: 2px 2px 5px 1px lighten($color-additional, 30%),
          -2px -2px 5px 1px lighten($color-additional, 30%);
        margin-bottom: 20px;
        border-radius: 5px;
        padding: 10px 20px;

        .meeting-time {
          font-weight: bold;
          color: #000;
        }
        .meeting-remaining-time {
          color: #d2d2d2;
        }
        .meeting-name {
          font-weight: bolder;
          color: #000;
          width: 30%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .leaves-card {
      height: 360px;

      .leave-staus-badge {
        padding: 3px;
        border-radius: 5px;
        color: #fff;
      }

      .status-approved {
        background-color: #7dc535;
      }
      .status-revised {
        background-color: #3871da;
      }

      .status-denied {
        background-color: #e62e2e;
      }

      .status-pending {
        background-color: orange;
      }
    }
  }
}
