.multi-file-picker {
  .file-chooser-button {
    border: none;
    padding: 0;

    &:disabled {
      opacity: 0.5;
    }

    & svg {
      width: 20px;
      height: 20px;
      transform: rotate(-45deg);
    }
  }
}
