.custom-tooltip {
  position: relative;
  display: inline-block;
}

.custom-tooltip .tooltip-container {
  visibility: hidden;
  width: max-content;
  background-color: black;
  color: #fff;
  font-size: 11px;
  text-align: center;
  border-radius: 6px;
  padding: 4px 12px;
  position: absolute;
  z-index: 1001;
}

.custom-tooltip .position-top {
  left: 50%;
  bottom: 130%;
  transform: translateX(-50%);
  margin-left: auto;
  margin-right: auto;
}

.custom-tooltip .position-bottom {
  left: 50%;
  top: 130%;
  transform: translateX(-50%);
  margin-left: auto;
  margin-right: auto;
}

.custom-tooltip .position-left {
  right: 130%;
  top: -3px;
}

.custom-tooltip .position-right {
  left: 130%;
  top: -3px;
}

.custom-tooltip .tooltip-container::after {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 5px;
}

.custom-tooltip .position-top::after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-color: black transparent transparent transparent;
}

.custom-tooltip .position-bottom::after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-color: transparent transparent black transparent;
}

.custom-tooltip .position-left::after {
  left: 100%;
  top: 50%;
  margin-top: -5px;
  border-color: transparent transparent transparent black;
}

.custom-tooltip .position-right::after {
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-color: transparent black transparent transparent;
}

.custom-tooltip:hover .tooltip-container {
  visibility: visible;
}
