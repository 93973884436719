.service-request-main {
  .service-request-list-item {
    width: 100%;
    height: 70px;
    padding: 10px 20px;
    background-color: #f0f0f0;
    border-radius: 5px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .unseen-badge {
      background-color: #4ce1b6;
      color: #fff;
    }
  }
}

.service-request-details {
  .text-editor {
    background-color: #fff;
  }

  .header-loader-wrapper {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .socket-connection-badge {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
  }
}
