.product-launch-sub-tabs {
  & > .nav-tabs {
    padding-left: 0px;
    border-bottom: none;

    & li .nav-link {
      border-radius: 5px;
      padding: 10px 15px !important;
      background-color: rgba(215, 179, 66, 0.6);
      color: #fff !important;
      margin-right: 10px !important;
      line-height: normal;

      &:hover {
        border-bottom: none;
        background-color: #d7b342 !important;
      }

      &.active {
        border-bottom: none;
        border-radius: 5px !important;
        background-color: #d7b342;
      }
    }
  }
  .tab-content {
    padding: 0px;
  }
}

.product-launch-document-tabs {
  & > .nav-tabs {
    padding-left: 0px;
    border-bottom: none;

    & li.nav-item {
      display: flex;
      text-transform: capitalize;

      & .nav-link {
        padding: 10px 20px;
        border-width: 2px;
        border-color: transparent;

        &.active {
          border-color: #0097b2 !important;
        }
      }
    }
  }
  .tab-content {
    padding: 0px;
  }
}
.bank-statment-tabs {
  @extend .product-launch-document-tabs;
  & > .nav-tabs {
    & li.nav-item {
      & .nav-link {
        &.active {
          //  border-color: #0097b2 !important;
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2),
            0 3px 5px 0 rgba(0, 0, 0, 0.19);
        }
      }
    }
  }
}
.operations-icon-btn {
  font-family: 'Roboto', sans-serif;
  margin-bottom: 0px;
  padding: 4px 9px;
  text-align: center;
  vertical-align: middle;
  display: inherit;
  border-radius: 3px;
  font-size: 0.9rem;
  line-height: 1.42857;

  svg {
    height: 18px;
    width: 18px;
    margin-right: 8px;
  }
}

.operations-form {
  textarea {
    resize: none;
  }
}
