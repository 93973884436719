.adjustment-form {
  input[type='radio']:disabled ~ .radio-btn__radio-custom {
    opacity: 0.5;
  }

  textarea {
    resize: none;
  }

  img.adjustment-item-image {
    width: 100%;
    max-width: 100px;
    height: auto;
    border-radius: 5px;
  }
}

.adjustment-reason-modal {
  max-width: 600px;

  .adjustment-reason-form {
    text-align: left;

    textarea {
      resize: none;
    }
  }
}

.adjustment-reasons {
  text-align: left;

  .reasons-list {
    max-height: 300px;
    overflow-y: auto;
  }

  .adjustment-reason-bar {
    position: relative;
    display: flex;
    background-color: lightgrey;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 3px;

    &:hover > .action-btn {
      opacity: 1;
      visibility: visible;
    }

    .reason-label {
      width: 100%;
    }

    .action-btn {
      opacity: 0;
      visibility: hidden;
      cursor: pointer;
      position: absolute;
      right: 5px;
      fill: #f70000;
      font-size: 20px;
      transition: 0.5s ease;
    }
  }
}
