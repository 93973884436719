.custom-receipt {
  max-width: 900px;
  margin: auto;

  .main-container {
    min-height: 70vh;
    width: 100%;
    position: relative;
    margin: 10px auto;
    box-shadow: 0px 2px 5px #c5c1c1, 0px 0px 5px #c5c1c1;
    font-family: 'Montserrat', sans-serif !important;
    font-size: 15px;

    .status-badge {
      position: relative;
      font-weight: bold;
      background-color: #23ac0e;
      color: #fff;
      text-transform: capitalize;
      height: 30px;
      width: fit-content;
      padding: 0px 10px;
      display: flex;
      justify-content: center;
      align-items: center;

      &::before {
        content: '';
        position: absolute;
        right: -20px;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 20px solid #23ac0e;
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
      }
    }

    .sub-container {
      padding: 20px;

      .header {
        display: flex;
        padding: 10px;

        .company-details {
          flex: 3;
          line-height: 0.6;

          .logo {
            width: 30%;
            height: auto;
            margin-bottom: 20px;
          }
        }

        .document-details {
          flex: 2;

          .title {
            font-size: 36px;
            font-weight: 700;
            text-transform: uppercase;
            line-height: 0.7;
          }

          .number {
            font-size: 16px;
            text-align: left;
            font-weight: bold;
            margin: 0px 0px 5px 0px;
          }

          table.info {
            width: 100%;
            margin-top: 5px;

            td {
              width: 50%;
              vertical-align: top;

              &:first-child {
                font-weight: bold;
              }

              &:last-child {
                text-align: right;
              }
            }
          }
        }
      }

      .body {
        table.main-data-table {
          width: 100%;
          border-collapse: collapse;
          margin: 10px 0;
          font-size: 13px;
          font-weight: bold;
          border: 0.2px solid #c7b671;

          thead th {
            text-align: center;
            border: 0.2px solid #c7b671;
            background-color: #f7e18b;
            font-weight: bolder;
            padding: 10px 5px;

            &:first-child {
              text-align: left;
            }
          }

          tbody td {
            text-align: center;
            border: 0.2px solid #c7b671;
            padding: 10px 5px;
            vertical-align: top;

            &:first-child {
              text-align: left;
            }

            .heading {
              display: block;
            }

            .description {
              display: block;
              font-weight: normal;
              color: #727272;
            }
          }
        }

        .extras {
          h5 {
            margin-top: 5px;
          }
        }
      }

      .footer {
        margin: 50px 0px;

        .stamp-signature {
          width: 15%;
          margin-bottom: 20px;
          margin-left: auto;

          .label {
            text-align: center;
            border-top: 1px solid #000;
          }
        }
      }
    }
  }
}
