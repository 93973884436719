.material-table__container {
  height: 68vh;
  max-height: 960px;

  @media screen and (min-height: 860px) {
    height: 77vh;
  }

  @media (min-height: 725px) and (max-height: 860px) {
    height: 73.5vh;
  }

  @media screen and (max-height: 500px) {
    height: 58vh;
  }
}
.MuiCell-text-wrap {
  word-wrap: break-word;
  width: 17%;
}
.icon-disabled {
  pointer-events: all !important;
  cursor: not-allowed !important;
}

.MuiTableCell-stickyHeader {
  left: auto !important;
}

.MuiTableCell-paddingNone {
  padding: 0 10px !important;
}
.MuiTableCell-padding {
  padding: 10px !important;
}

.material-table {
  th {
    white-space: nowrap;
  }
}

.material-table__toolbar-wrap {
  position: absolute;
  top: 10px;
  @include directify($directions) {
    #{directed('right')}: 0px;
  }

  .material-table__toolbar {
    padding: 0 30px;
  }

  .material-table__toolbar-button {
    height: 36px;
    width: 36px;
    padding: 0;
  }
}

.material-table__toolbar-selected {
  @include directify($directions) {
    #{directed('margin-right')}: 10px;
  }
}

.material-table__row {
  transition: 0.3s;
  cursor: pointer;

  &[aria-checked='true'] {
    @include themify($themes) {
      background-color: themed('colorHover');
    }

    .material-table__checkbox span {
      color: $color-primary;
    }
  }

  &:hover {
    @include themify($themes) {
      background-color: themed('colorBackgroundBody');
    }
  }
}

.material-table__checkbox--checked span {
  color: $color-primary;
}

.material-table__cell-right {
  @include directify($directions) {
    text-align: directed('left');
  }
}

.material-table__cell.material-table__cell--sort {
  span {
    transition: 0.3s;
    font-size: 0.95rem;

    @include themify($themes) {
      color: themed('colorText');
    }

    &:hover {
      color: $color-primary;
    }
  }
}

.material-table__cell,
.material-table__sort-label,
.material-table__toolbar-button {
  white-space: nowrap;
  @include themify($themes) {
    color: themed('colorText');
  }
}
.lock {
  margin: 10px;
}
.material-table__locked,
.material-table__checkbox {
  transition: 0.3s;

  @include themify($themes) {
    color: themed('colorIcon');
  }
}

.material-table__pagination {
  @include directify($directions) {
    float: directed('right');
  }

  button:hover {
    @include themify($themes) {
      background-color: themed('colorHover') !important;
    }
  }

  & > div {
    padding: 0;
  }

  span,
  div,
  svg {
    @include themify($themes) {
      color: themed('colorText');
    }
  }
}

.material-table__wrap {
  overflow-x: auto;
}

.material-table__filter-menu {
  div:last-child {
    box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.06);
  }

  .material-table__filter-menu-item {
    color: #646777;
    transition: 0.3s;
    font-size: 13px;
    padding: 7px 24px;
    height: auto;

    &:hover {
      background: #fafbfe;
    }
  }
}

@media screen and (max-width: 768px) {
  .material-table__pagination {
    div {
      margin-left: 8px;
      margin-right: 8px;

      &:last-child {
        @include directify($directions) {
          #{directed('margin-right')}: 0px;
        }
      }

      &:first-child {
        margin: 0;
      }
    }

    div > span:first-of-type {
      display: none;
    }
  }
}

@media screen and (max-width: 420px) {
  .material-table__toolbar-selected {
    span {
      display: none;
    }
  }
}
