// Custom Designed Accordion
.custom-accordion {
  margin: 16px 0px 0px 0px !important;

  .MuiAccordionSummary-root.Mui-expanded {
    min-height: inherit !important;
  }

  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0px !important;
  }

  .MuiAccordionDetails-root {
    border: 1px solid #8080804f;
  }
}

.custom-accordion:first-child {
  margin: 0px !important;
}
