.document-previewer {
  height: 70vh;

  #proxy-renderer {
    overflow: hidden;

    #image-renderer {
      background-image: none;
    }

    #pdf-renderer {
      background-color: #e5e5e5;

      #pdf-controls {
        background-color: #000000cc;
        padding: 20px;

        button {
          margin-left: 10px;
          border-radius: 5px;
          background-color: #e0c46c;

          svg {
            path {
              fill: #fff;
            }
          }
        }

        & > *:not(#pdf-zoom-out):not(#pdf-zoom-in) {
          display: none;
        }
      }
    }
  }
}
