.collapse__wrapper {
  @include directify($directions) {
    text-align: directed('left');
  }
  &.opened,
  &.opening {
    .collapse__title {
      p {
        color: $color-additional;
      }

      svg {
        fill: $color-additional;
      }
    }
  }

  &.boxed {
    border: solid 1px #dddddd;
    margin-top: -1px;

    .collapse__title {
      background-color: #f2f4f7;
      border-bottom: solid 1px #dddddd;
      padding: 8px 15px;

      p {
        color: #444444;
      }

      svg {
        display: none;
      }
    }

    .collapse__content {
      @include directify($directions) {
        #{directed('padding-right')}: 20px;
        #{directed('padding-left')}: 15px;
      }
      padding-top: 16px;
      padding-bottom: 20px;
    }

    &.closed {
      .collapse__title {
        border-bottom: none;
      }
    }
  }

  &.with-arrow {
    margin-bottom: 10px;

    .collapse__content {
      @include directify($directions) {
        #{directed('padding-right')}: 10px;
        #{directed('padding-left')}: 15px;
      }
      padding-top: 15px;
      padding-bottom: 10px;

      @include themify($themes) {
        color: themed('colorText');
      }
    }

    .collapse__title {
      padding-top: 15px;
      padding-bottom: 15px;
      border-bottom: 0.1px solid #ece1e1;
      transition: background-color 0.3s;

      @include themify($themes) {
        color: themed('colorText');
      }

      @include directify($directions) {
        #{directed('padding-left')}: 0px;
        #{directed('padding-right')}: 15px;
      }

      &:hover {
        @include themify($themes) {
          background-color: themed('colorHover');
        }
      }

      &--icon {
        display: none;
      }

      p {
        svg {
          display: block;
          top: calc(50% - 7px);
          transition: all 0.3s;

          @include directify($directions) {
            #{directed('right')}: -15px;
          }

          @include themify($themes) {
            fill: themed('colorText');
          }
        }
      }
    }

    &.opened .collapse__title,
    &.opening .collapse__title {
      p {
        color: inherit;
      }

      svg {
        fill: inherit;
        transform: rotate(180deg);
      }
    }
  }

  &.with-right-arrow {
    margin-bottom: 10px;

    .collapse__content {
      @include directify($directions) {
        #{directed('padding-right')}: 10px;
        #{directed('padding-left')}: 15px;
      }
      padding-top: 15px;
      padding-bottom: 10px;

      @include themify($themes) {
        color: themed('colorText');
      }
    }

    .collapse__title {
      padding-top: 15px;
      padding-bottom: 15px;
      transition: background-color 0.3s;
      width: inherit;

      @include themify($themes) {
        color: themed('colorText');
      }

      @include directify($directions) {
        #{directed('padding-left')}: 15px;
        #{directed('padding-right')}: 15px;
      }

      &--icon {
        display: none;
      }

      p {
        padding-left: 10px;
        svg {
          display: block;
          top: calc(50% - 10px);
          transform: rotate(-90deg);
          transition: all 0.3s;

          @include directify($directions) {
            #{directed('left')}: -15px;
          }

          @include themify($themes) {
            fill: themed('colorText');
          }
        }
      }
    }

    &.opened .collapse__title,
    &.opening .collapse__title {
      p {
        color: inherit;
      }

      svg {
        fill: inherit;
        transform: rotate(0deg);
      }
    }
  }

  &.with-shadow {
    margin-bottom: 10px;
    box-shadow: 0px 0px 5px #c5c1c1;

    .collapse__content {
      @include directify($directions) {
        #{directed('padding-right')}: 10px;
        #{directed('padding-left')}: 15px;
      }
      padding-top: 15px;
      padding-bottom: 10px;

      @include themify($themes) {
        color: themed('colorText');
      }
    }

    .collapse__title {
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 15px;
      padding-right: 25px;
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.05);
      transition: background-color 0.3s;
      border-bottom: 1px solid #d4d0d0;

      @include themify($themes) {
        color: themed('colorText');
      }

      &:hover {
        @include themify($themes) {
          background-color: themed('colorHover');
        }
      }

      &--icon {
        display: none;
      }

      p {
        svg {
          display: block;
          top: calc(50% - 7px);
          transition: all 0.3s;

          @include themify($themes) {
            fill: themed('colorText');
          }
        }
      }
    }

    &.opened .collapse__title,
    &.opening .collapse__title {
      p {
        color: inherit;
      }

      svg {
        fill: inherit;
        transform: rotate(180deg);
      }
    }
  }

  &.custom-collapse {
    margin-bottom: 10px;
    box-shadow: 0px 0px 2px #c5c1c1;

    .collapse__content {
      @include directify($directions) {
        #{directed('padding-right')}: 10px;
        #{directed('padding-left')}: 15px;
      }
      padding-top: 15px;
      padding-bottom: 10px;

      @include themify($themes) {
        color: themed('colorText');
      }
    }

    .collapse__title {
      padding-top: 10px;
      padding-bottom: 12px;
      padding-left: 30px;
      padding-right: 25px;
      transition: background-color 0.3s;
      background-color: #f3f8fe;

      @include themify($themes) {
        color: themed('colorText');
      }

      &--icon {
        svg {
          top: 12px;
          left: 10px;
        }
      }

      p {
        font-weight: 400;
        font-size: 17px;
        svg {
          display: block;
          top: calc(50% - 7px);
          transition: all 0.3s;
          transform: rotate(-90deg);

          // @include themify($themes) {
          //   fill: themed('colorText');
          // }
        }
      }
    }

    &.opened .collapse__title,
    &.opening .collapse__title {
      p {
        color: inherit;
      }

      svg {
        transform: rotate(0deg);
      }
    }
  }

  &.closing {
    .collapse__content {
      padding-top: 0;
      padding-bottom: 0;
      transition: all 0.3s;
    }
  }
}

.collapse__title,
.collapse__content {
  @include directify($directions) {
    #{directed('padding-left')}: 20px;
    #{directed('padding-right')}: 15px;
  }
}

.collapse__title {
  padding-bottom: 10px;
  padding-top: 6px;
  cursor: pointer;
  position: relative;
  background: transparent;
  display: block;
  width: 100%;
  border: none;
  @include directify($directions) {
    text-align: directed('left');
  }

  p {
    font-weight: 700;
    margin-top: 0;
    transition: all 0.3s;
    position: relative;

    svg {
      @include directify($directions) {
        #{directed('right')}: -10px;
        #{directed('left')}: auto;
      }
      display: none;
    }
  }

  svg {
    position: absolute;
    @include directify($directions) {
      #{directed('left')}: 0px;
    }
    width: 20px;
    height: 20px;
    top: 8px;
    transition: all 0.3s;
  }
}

.collapse__content {
  padding-bottom: 14px;
}
