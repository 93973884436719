.chat-module-wrapper {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .chat-module {
    width: 100%;
    padding: 20px 50px;
    overflow-y: auto;

    .message {
      position: relative;
      display: flex;
      flex-direction: column;
      margin: 5px 0;
      width: fit-content;
      max-width: 65%;
      padding: 12px;
      border-radius: 10px;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        width: 20px;
        height: 20px;
      }

      .content {
        word-break: break-all;

        & > * {
          margin: 0;
        }
      }
    }

    .my_message {
      text-align: right;
      background-color: #eccb5d;

      &::before {
        right: -12px;
        background: linear-gradient(
          135deg,
          #eccb5d 0%,
          #eccb5d 50%,
          transparent 50%,
          transparent
        );
      }
    }

    .friend_message {
      text-align: left;
      background-color: #f0f0f0;

      &::before {
        left: -12px;
        background: linear-gradient(
          225deg,
          #f0f0f0 0%,
          #f0f0f0 50%,
          transparent 50%,
          transparent
        );
      }
    }

    .attachments {
      margin: 10px 0px;
      display: flex;
      flex-wrap: wrap;

      .attachment {
        width: 70px;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #e6e6e6;
        margin: 10px 10px 0px 0px;
        border-radius: 5px;
        cursor: pointer;

        .content {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          padding: 5px;

          small {
            width: 100%;
            padding-top: 5px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .footer {
      font-size: 10px;
      display: block;
      margin-top: 5px;
      opacity: 0.5;
    }

    /* width */
    &::-webkit-scrollbar {
      width: 10px;
      height: 7px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f7f9fa;
      border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #b3c2d2;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #4e4e4e;
    }
  }
}
