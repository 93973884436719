/*********** Logo And Receipt Info CSS *************/
.payment-receipt-container {
  max-width: 900px;
  margin: 30px auto;
  padding-top: 20px;
  box-shadow: 0px 2px 5px #c5c1c1, 0px 0px 5px #c5c1c1;
  font-family: 'Montserrat', sans-serif !important;
}

.logo-section img {
  width: 70%;
  height: auto;
  /* object-fit: contain; */
}
.logo-section h5 {
  font-weight: 700;
  margin: 10px 0px;
}
.logo-section p {
  font-size: 16px;
  // font-weight: 700;
  /* color: #544d4d; */
  color: #292727;
  margin-bottom: 8px;
  line-height: 0.5;
}
.logo-section p span {
  padding-left: 10px;
}

#payment {
  font-weight: 600;
  margin-bottom: 20px;
}
.receipt-info {
  background-color: #f6f6f6;
  padding: 5px 10px;
}
.receipt-info p {
  margin: 2px;
  font-size: 15px;
  font-weight: 500;
}
#bill {
  margin-bottom: 8px;
}

#bill span {
  color: #4a4848;
  font-weight: 700;
}

/************** Invoice CSS ****************/

.invoice-headings p {
  /* margin-bottom: 25px; */
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 700;
}

.invoice-details p {
  margin-bottom: 0px;
  color: #868282;
  font-weight: 700;
  font-size: 15px;
  padding-left: 10px;
}
.invoice-details hr {
  margin-bottom: 1rem;
  margin-top: 0px;
}

.amount {
  background-color: #0097b2;
  text-align: center;
  padding: 30px;
}
.amount p {
  margin-bottom: 2px;
  font-weight: 700;
  font-size: 18px;
  color: #ffff;
}

.over-payment {
  margin: 30px 0;
  border-bottom: 1px solid #dbd7d7;
  border-top: 1px solid #dbd7d7;

  h5 {
    font-weight: bold;
    color: $color-additional;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  h4 {
    margin-bottom: 20px;
  }
}

/************** payment-for CSS *************/

.payment-for-heading {
  font-weight: 700;
  color: black;
}

.payment-headings {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  padding: 15px;
  text-align: center;
}
.payment-headings p {
  // margin-bottom: 0px;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 14px;
  color: white;
  width: 100%;
  margin: 0 !important;
}
.payment-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fafafa;
  border: 1px solid #d0cbcb;
  color: black;
  padding: 15px;
  border-top: none;
  text-align: center;
}
.payment-details p {
  font-size: 12px;
  width: 100%;
  margin: 0 !important;
}
.payment-details a {
  font-size: 12px;
  font-weight: bold;
  width: 100%;
}
