.file-handler {
  width: 100%;
  height: 100%;

  // logistics collapse issue resolved
  .e-sheet-panel {
    height: 80% !important;
  }

  .spreadsheet-container {
    height: fit-content;
    margin-bottom: 8% !important;
  }
}
