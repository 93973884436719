.d-grid {
  display: grid !important;

  //  ====================== Gap ====================
  &.gap-1 {
    gap: 10px;
  }

  &.gap-2 {
    gap: 20px;
  }

  &.gap-3 {
    gap: 30px;
  }

  &.gap-4 {
    gap: 40px;
  }

  &.gap-5 {
    gap: 50px;
  }

  //  ====================== Rows Gap ====================
  &.row-gap-1 {
    row-gap: 10px;
  }

  &.row-gap-2 {
    row-gap: 20px;
  }

  &.row-gap-3 {
    row-gap: 30px;
  }

  &.row-gap-4 {
    row-gap: 40px;
  }

  &.row-gap-5 {
    row-gap: 50px;
  }

  // ==================== Columns Gap ====================
  &.col-gap-1 {
    column-gap: 10px;
  }

  &.col-gap-2 {
    column-gap: 20px;
  }

  &.col-gap-3 {
    column-gap: 30px;
  }

  &.col-gap-4 {
    column-gap: 40px;
  }

  &.col-gap-5 {
    column-gap: 50px;
  }
}
