.family-info-card {
  position: relative;
  background-color: #efefef;
  border-radius: 10px;
  padding: 50px;
  margin-bottom: 10px;
}

.family-info-card-remove-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  fill: #bd0202;
  cursor: pointer;
}
